body.login {
	background: #FFF;
	h1 {
		a {
			width: 89px;
			height: 72px;
			background: url(../img/haus-group-text.png) no-repeat center center / contain;
		}
	}
	form {
		box-shadow: none;
	}
}

#login {
	background: #FFF;
	box-shadow: none;
}